import * as React from 'react'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { CLEAR_ALL, useProductsDispatch } from '../store'

const ThankYouPage = () => {
  const dispatch = useProductsDispatch()

  useEffect(() => {
    dispatch({
      type: CLEAR_ALL,
    })
  })
  return (
    <Layout>
      <Seo title='Our Shop' />
      <div className='container text-center px-7'>
        <h1 className='pt-5 pb-3'>Thank you</h1>
        <p>
          You made our day, thank you for choosing us for your purchase. We are
          happy to meet your purchase order and value our loyal customers.
        </p>

        <p>
          We&apos;re getting your order ready to be shipped. You will email your
          receipt and feel to get in touch with us for any{' '}
          <Link to='/contact-us' className=''>
            inquiries
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default ThankYouPage
